import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "saldos-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  msDepositos: "https://nomina.uat-ms.axkans.org/nomina/depositos",
  msCompras: "https://compras.uat-ms.axkans.org/compras/",
  msNominas: "https://nomina.uat-ms.axkans.org/nomina/nomina",
  msPagos: "https://nomina.uat-ms.axkans.org/nomina/pagos-varios",
  msSaldos: "https://saldos.uat-ms.axkans.org/saldos/saldo",
  msReportes: "https://saldos.uat-ms.axkans.org/saldos/reportes",

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
}