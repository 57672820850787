import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SaldosPsService } from '../../procs/saldos/saldos-ps.service';
import { SaldosGenerales } from '../../api/saldos-generales';
import { ClaveEntradaSalida } from '../../api/enums/clave-entrada-salida.enum';
import { MovimientosDetalle } from '../../api/movimientos-detalle';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'axks-saldos',
  templateUrl: './saldos.component.html',
  styleUrls: ['./saldos.component.scss']
})
export class SaldosComponent extends BaseView implements OnInit {

  showMovimientos: boolean = false;
  movimientosMostrar: MovimientosDetalle[];
  saldosGenerales: SaldosGenerales;
  clavesEntradaSalida = ClaveEntradaSalida;
  fechaInicial: string;
  fechaFinal: string;
  mesConfig: number = 0;
  yearRange: string;
  switchToolTip: string;
  filtroMes: boolean = true;
  myLoadContent: boolean;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientSaldos: SaldosPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);

    this.myLoadContent = false;
  }

  ngOnInit(): void {

    if (!this.saldosGenerales) {
      this.saldosGenerales = {};
    }

    if (!this.movimientosMostrar) {
      this.movimientosMostrar = [];
    }

    this.initRangoFechas();
    this.initCargaSaldosGenerales();
  }

  /**
   * Metodo para inicializar los rangos de fecha con el principio y fin del mes actual.
   */
  initRangoFechas() {
    // Se inicializa fecha inicial de la consulta
    let fecIni = new Date();
    fecIni.setDate(1);
    fecIni.setMonth(fecIni.getMonth() - this.mesConfig);

    // Se inicializa fecha final de la consulta
    let fecFin = new Date();
    fecFin.setDate(1);
    fecFin.setMonth(fecFin.getMonth() - this.mesConfig);
    fecFin.setMonth(fecFin.getMonth() + 1);
    fecFin.setDate(fecFin.getDate() - 1);

    // Se le da formato a las fechas
    let pipe = new DatePipe('es-MX');
    this.fechaInicial = pipe.transform(fecIni, 'yyyy-MM-dd', 'America/Mexico_City');
    this.fechaFinal = pipe.transform(fecFin, 'yyyy-MM-dd', 'America/Mexico_City');

    // Se inicializa el rango de años
    this.yearRange = new Date().getFullYear() - 1 + ":" + new Date().getFullYear();
  }

  /**
   * Metodo para configurar las fechas iniciales y finales por medio de los filtros.
   */
  configRangoFechas(event) {
    // Se genera la fecha inicial del evento recuperado del componente.
    let fecIni = new Date(event);
    
    // Se genera la fecha final
    let fecFin = new Date(event);
    fecFin.setMonth(fecFin.getMonth() + 1);
    fecFin.setDate(fecFin.getDate() - 1);

    // Se recuperan las fechas con formato
    this.fechaInicial = formatDate(fecIni, 'yyyy-MM-dd', 'es-MX', 'America/Mexico_City');
    this.fechaFinal = formatDate(fecFin, 'yyyy-MM-dd', 'es-MX', 'America/Mexico_City');
  }

  initCargaSaldosGenerales() {
    if(this.myLoadContent == true){
      this.myLoadContent = false;
    }
    this.showMovimientos = false;
    this.movimientosMostrar = [];
    this.clientSaldos.getSaldosGenerales(this.fechaInicial, this.fechaFinal).subscribe(
      (data) => {
        this.saldosGenerales = data;
        this.myLoadContent = true;
      },
      (error) => {
        this.myLoadContent = true;
        this.errorMessage("Error saldos", "No se pudo consultar los saldos generales.")
      }
    );
  }

  selectFiltroRangoFechas(event) {
    this.filtroMes = false;
  }

  selectFiltroMes(event) {
    this.filtroMes = true;
  }

  mostrarTabla(event) {
    this.showMovimientos = true;
    this.showProgress = true;
    this.clientSaldos.getMovimientos(this.fechaInicial, this.fechaFinal, event.data.cvEntradaSalida).subscribe(
      (data) => {
        this.movimientosMostrar = data;
        this.showProgress = false;
      },
      (error) => {
        this.errorMessage("Error de movimientos", "No se pudo consultar los movimientos");
        this.showProgress = false;
      }
    );
  }

  ocultarTabla(event) {
    this.showMovimientos = false;
    this.movimientosMostrar = [];
  }

  exportExcelSaldosGenerales() {
    let saldos :any[] = [];
    saldos.push({
      saldo: this.saldosGenerales.saldo,
      ingresos: this.saldosGenerales.ingresos,
      egresos: this.saldosGenerales.egresos
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(saldos);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Saldos-Generales");
    });
  }

  exportExcelEntradasSalidas() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.saldosGenerales.entradasSalidas);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Entradas-Salidas");
    });
  }

  exportExcelMovimientos() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.movimientosMostrar);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Movimientos");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()) + 
      "-" + (mes < 10 ? "0" + mes : mes) +
      "-" + now.getFullYear() +
      "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
      "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
      "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }

}
