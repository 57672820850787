import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeESMX from "@angular/common/locales/es-MX";
import { HttpClientModule } from '@angular/common/http';

import { ActionsBarModule, BaseMessages, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';

import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';

import { environment } from 'src/environments/environment';
import { HomeComponent } from './view/home/home.component';
import { SaldosComponent } from './view/saldos/saldos.component';
import { TableModule } from 'primeng/table';
import { ReportesSaldosComponent } from './view/reportes/reportes-saldos/reportes-saldos.component';

registerLocaleData(localeESMX, "es-MX");

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SaldosComponent,
    ReportesSaldosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    InputTextModule,
    InputNumberModule,
    RadioButtonModule,
    DropdownModule,
    HttpClientModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    PanelModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    ScrollPanelModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    TooltipModule,
    TableModule,
    TreeTableModule
  ],
  providers: [
    MessageService, SessionClientProvider, BaseMessages,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: "env", useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
