import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SaldosGenerales } from '../../api/saldos-generales';
import { MovimientosDetalle } from '../../api/movimientos-detalle';

@Injectable({
  providedIn: 'root'
})
export class SaldosRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msSaldos;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getSaldosGenerales(fecIni: string, fecFin: string) : Observable<SaldosGenerales>{
    let path = this.getBaseEndpoint() + "montos-generales?fecha-inicial=" + fecIni + "&fecha-final=" + fecFin;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<SaldosGenerales>;
  }

  getMovimientos(fecIni: string, fecFin: string, clave: number) : Observable<MovimientosDetalle[]>{
    let path = this.getBaseEndpoint() + "movimientos?fecha-inicial=" + fecIni + "&fecha-final=" + fecFin + "&clave="+clave;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<MovimientosDetalle[]>;
  }
}
