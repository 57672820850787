import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { MontosTotales } from '../../../api/reportes';
import { ReportesPsService } from '../../../procs/reportes/reportes-ps.service';

@Component({
  selector: 'axks-reportes-saldos',
  templateUrl: './reportes-saldos.component.html',
  styleUrls: ['./reportes-saldos.component.scss']
})
export class ReportesSaldosComponent extends BaseView implements OnInit {

  audio = new Audio();

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientReportes: ReportesPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  item: MontosTotales;
  showImage: boolean = false;

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.initCargaInformacion();
    this.loadAudio();
  }

  initAnimacion(event) {
    if (event.node.parent == null) {
      this.playAudio();
      this.showImage = true;
      setTimeout(() => {
        this.showImage = false;
      }, 2000);
    }
  }

  loadAudio() {
    this.audio.src = "/assets/sounds/cash-register-141427.mp3";
    this.audio.load();
  }

  playAudio(){
    this.audio.play();
  }

  initCargaInformacion() {
    this.showProgress = true;
    this.clientReportes.montosTotales().subscribe(
      (data) => {
        this.item = data;
        this.showProgress = false;
      },
      (error) => {
        this.errorMessage("Error de carga", "No se pudo obtener los montos.");
        this.showProgress = false;
      }
    );
  }

}
