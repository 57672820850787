import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { SaldosRestcService } from '../../client/saldos/saldos-restc.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SaldosGenerales } from 'src/app/api/saldos-generales';
import { MovimientosDetalle } from '../../api/movimientos-detalle';

@Injectable({
  providedIn: 'root'
})
export class SaldosPsService extends BaseProcess<SaldosRestcService>{

  client: SaldosRestcService;

  constructor(httpClient: HttpClient, crest: SaldosRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  getSaldosGenerales(fecIni: string, fecFin: string): Observable<SaldosGenerales> {
    return this.client.getSaldosGenerales(fecIni, fecFin);
  }
  
  getMovimientos(fecIni: string, fecFin: string, clave: number): Observable<MovimientosDetalle[]>{
    return this.client.getMovimientos(fecIni, fecFin, clave);
  }
}
