<div class="progress container" *ngIf="!myLoadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<!-- {{fechaInicial | json}} : {{fechaFinal | json}} -->
<ng-container *ngIf="myLoadContent">

    <div class="p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3">
            <h2>Estado de cuenta</h2>
        </div>
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-9 p-xl-9">
            <div class="p-grid p-jc-end">
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3" *ngIf="filtroMes">
                    <br>
                    <span class="p-float-label">
                        <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true" [yearRange]="yearRange"
                            [readonlyInput]="true" inputId="cal-month" [showIcon]="true"
                            (onSelect)="configRangoFechas($event)">
                        </p-calendar>
                        <label for="cal-month">Mes</label>
                    </span>
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3" *ngIf="!filtroMes">
                    <br>
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="fechaInicial" dateFormat="yy-mm-dd" dataType="string"
                            [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true"
                            [readonlyInput]="true" inputId="cal-ini" [showIcon]="true"></p-calendar>
                        <label for="cal-ini">Fecha inicial</label>
                    </span>
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3" *ngIf="!filtroMes">
                    <br>
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="fechaFinal" dateFormat="yy-mm-dd" dataType="string"
                            [yearNavigator]="true" [yearRange]="yearRange" [readonlyInput]="true" inputId="cal-fin"
                            [showIcon]="true"></p-calendar>
                        <label for="cal-fin">Fecha final</label>
                    </span>
                </div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3">
                    <br>
                    <button pButton label="Buscar" icon="pi pi-search" (click)="initCargaSaldosGenerales()"></button>
                    &nbsp;
                    <button pButton *ngIf="filtroMes" icon="pi pi-filter" pTooltip="Consulta por rango de fechas"
                        (click)="selectFiltroRangoFechas($event)"></button>
                    <button pButton *ngIf="!filtroMes" icon="pi pi-filter" pTooltip="Consulta por mes"
                        (click)="selectFiltroMes($event)"></button>
                </div>
            </div>
        </div>
    </div>
    <!-- <br> -->
    <!-- {{saldosGenerales | json}} -->
    <!-- TABLA SALDOS GENERALES -->
    <div class="p-grid">
        <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <p-table>
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center">
                        <span>Saldos del periodo: {{fechaInicial | date: "dd 'de' MMMM 'de' yyyy": 'es-MX'}} al
                            {{fechaFinal | date: "dd 'de' MMMM 'de' yyyy": 'es-MX'}}</span>
                        <span class="p-input-icon-left p-ml-auto">
                            <button type="button" pButton pRipple icon="pi pi-file-excel"
                                (click)="exportExcelSaldosGenerales()" class="p-button-success p-ml-auto"
                                pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-size-header">Saldo:</th>
                        <td class="text-size-body">
                            <!-- <span class="p-column-title">Saldo:</span> -->
                            &nbsp;{{saldosGenerales.saldo | currency: 'MXN'}}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-size-header">Ingresos:</th>
                        <td class="text-size-body">
                            <!-- <span class="p-column-title">Ingresos:</span> -->
                            &nbsp;{{saldosGenerales.ingresos | currency: 'MXN'}}
                        </td>
                    </tr>
                    <tr>
                        <th class="text-size-header">Egresos:</th>
                        <td class="text-size-body">
                            <!-- <span class="p-column-title">Egresos:</span> -->
                            &nbsp;{{saldosGenerales.egresos | currency: 'MXN'}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- TABLA ENTRADAS Y SALIDAS -->
        <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <p-table selectionMode="single" [responsive]="true"
                [value]="saldosGenerales.entradasSalidas" (onRowSelect)="mostrarTabla($event)"
                (onRowUnselect)="ocultarTabla($event)">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center">
                        <span>Entradas y salidas</span>
                        <span class="p-input-icon-left p-ml-auto">
                            <button type="button" pButton pRipple icon="pi pi-file-excel"
                                (click)="exportExcelEntradasSalidas()" class="p-button-success p-ml-auto"
                                pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="concepto">
                            Concepto<p-sortIcon field="concepto"></p-sortIcon>
                        </th>
                        <th pSortableColumn="monto">
                            Monto<p-sortIcon field="monto"></p-sortIcon>
                        </th>
                        <th pSortableColumn="movimiento">
                            Movimiento<p-sortIcon field="movimiento"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr [pSelectableRow]="item">
                        <td>
                            <span class="p-column-title">Concepto</span>
                            {{item.concepto}}
                        </td>
                        <td>
                            <span class="p-column-title">Monto</span>
                            {{item.monto | currency: 'MXN'}}
                        </td>
                        <td>
                            <span class="p-column-title">Movimiento</span>
                            {{item.movimiento}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- TABLA MOVIMIENTOS -->
        <div class="p-col-12" *ngIf="showMovimientos">
            <p-table #tm [value]="movimientosMostrar" [responsive]="true"
                selectionMode="single" [resizableColumns]="true" [paginator]="true" [showCurrentPageReport]="true"
                [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]" [globalFilterFields]="['concepto','monto']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center">
                        <span>Movimientos</span>
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="tm.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar" [style]="{'width':'auto'}" />&nbsp;&nbsp;
                            <button type="button" pButton pRipple icon="pi pi-file-excel"
                                (click)="exportExcelMovimientos()" class="p-button-success p-ml-auto"
                                pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="concepto">Concepto<p-sortIcon field="concepto"></p-sortIcon>
                        </th>
                        <th pSortableColumn="monto">Monto<p-sortIcon field="monto"></p-sortIcon>
                        </th>
                        <th pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-value>
                    <tr [pSelectableRow]="value">
                        <td>
                            <span class="p-column-title">Concepto</span>
                            {{value.concepto}}
                        </td>
                        <td>
                            <span class="p-column-title">Monto</span>
                            {{value.monto | currency: 'MXN'}}
                        </td>
                        <td>
                            <span class="p-column-title">Fecha</span>
                            {{value.fecha | date: "dd 'de' MMMM 'de' yyyy"}}
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </div>
    </div>

</ng-container>
<p-toast></p-toast>