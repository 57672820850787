import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './view/home/home.component';
import { SaldosComponent } from './view/saldos/saldos.component';
import { ReportesSaldosComponent } from './view/reportes/reportes-saldos/reportes-saldos.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'saldos', component: SaldosComponent },
  { path: 'reporte-saldos', component: ReportesSaldosComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
