import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { ReportesRestcService } from '../../client/reportes/reportes-restc.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MontosTotales } from '../../api/reportes';

@Injectable({
  providedIn: 'root'
})
export class ReportesPsService extends BaseProcess<ReportesRestcService>{

  client: ReportesRestcService;

  constructor(httpClient: HttpClient, crest: ReportesRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  montosTotales(): Observable<MontosTotales> {
    return this.client.montosTotales();
  }
}
