<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="!showProgress">
    <div style="background-color: #f8f9fa; padding: 0.1rem;">
        <h2>Reporte de saldos</h2>
    </div>

    <div *ngIf="showImage" class="contenedor-img">
        <i class="saldo-img"></i>
    </div>

    <!-- {{item | json}} -->
    <div class="p-grid" style="background-color: #f8f9fa;">
        <div class="p-col-12">
            <p-card>
                <p-treeTable [value]="item.ingresos" (onNodeExpand)="initAnimacion($event)">
                    <ng-template pTemplate="caption">
                        Ingresos
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Concepto</th>
                            <th>SubTotal (Sin IVA)</th>
                            <th>Total (Con IVA)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                        <tr [ttRow]="rowNode">
                            <td>
                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                {{rowData.concepto}}
                            </td>
                            <td>{{rowData.subtotal | currency: 'MXN'}}</td>
                            <td>{{rowData.total | currency: 'MXN'}}</td>
                        </tr>
                    </ng-template>
                </p-treeTable>
            </p-card>
        </div>
        <div class="p-col-12">
            <p-card>
                <p-treeTable [value]="item.distribucion">
                    <ng-template pTemplate="caption">
                        Distribución
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Concepto</th>
                            <th>SubTotal (Sin IVA)</th>
                            <th>Total (Con IVA)</th>
                            <th>Distribución real (57%)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                        <tr [ttRow]="rowNode">
                            <td>
                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                {{rowData.concepto}}
                            </td>
                            <td>{{rowData.subtotal | currency: 'MXN'}}</td>
                            <td>{{rowData.total | currency: 'MXN'}}</td>
                            <td>{{rowData.distReal | currency: 'MXN'}}</td>
                        </tr>
                    </ng-template>
                </p-treeTable>
            </p-card>
        </div>
        <div class="p-col-12">
            <p-card>
                <p-treeTable [value]="item.depositos">
                    <ng-template pTemplate="caption">
                        Depositos
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Concepto</th>
                            <th>SubTotal (Sin IVA)</th>
                            <th>Total (Con IVA)</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                        <tr [ttRow]="rowNode">
                            <td>
                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                {{rowData.concepto}}
                            </td>
                            <td>{{rowData.subtotal | currency: 'MXN'}}</td>
                            <td>{{rowData.total | currency: 'MXN'}}</td>
                        </tr>
                    </ng-template>
                </p-treeTable>
            </p-card>
        </div>
    </div>

</ng-container>